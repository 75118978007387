// eslint-disable-next-line no-console
console.debug(`${APP_NAME}: v${APP_VERSION}`)

// Router
export { default as moaRouter } from './router/moaRouter'

// i18n
export { translator } from './i18n/i18n'

// gtm
export { default as useGtm } from './gtm/useGtm'

// Composables
export { default as useData } from './composables/useData'
export { default as useConfigData } from './composables/useConfigData'
export { default as useScrollbar } from './composables/useScrollbar'
export { default as useDemo } from './composables/useDemo'
export { default as useCreateFrontendCssVars } from './composables/useCreateFrontendCssVars'
export { default as useResponsive } from './composables/useResponsive'

// Shared components
export { default as OlySelect } from './components/OlySelect/OlySelect.vue'
export { default as OlyButtonV2 } from './components/OlyButton/OlyButtonV2.vue'
export { default as OlyIconButton } from './components/OlyIconButton/OlyIconButton.vue'
export { default as OlyLabel } from './components/OlyLabel/OlyLabel.vue'
export { default as OlySectionHeader } from './components/OlySectionHeader/OlySectionHeader.vue'
export { default as OlyHoverContainer } from './components/OlyHoverContainer/OlyHoverContainer.vue'
export { default as OlyTile } from './components/OlyTile/OlyTile.vue'
export { default as OlyIcon } from './components/OlyIcon/OlyIcon.vue'
export { default as OlyIconV2 } from './components/OlyIconV2/OlyIconV2.vue'
export { default as OlyTileContainer } from './components/OlyTileContainer/OlyTileContainer.vue'
export { default as OlyTextBox } from './components/OlyTextBox/OlyTextBox.vue'
export { default as OlyCollapse } from './components/OlyCollapse/OlyCollapse.vue'
export { default as OlyLoading } from './components/OlyLoading/OlyLoading.vue'
export { default as OlyPopup } from './components/OlyPopup/OlyPopup.vue'
export { default as OlyPopupV2 } from './components/OlyPopupV2/OlyPopupV2.vue'
export { default as OlyDemoFeaturesSwitch } from './components/OlyDemoFeaturesSwitch/OlyDemoFeaturesSwitch.vue'
export { default as ContactUs } from './components/ContactUs/ContactUs.vue'
export { default as OlyToggle } from './components/OlyToggle/OlyToggle.vue'
export { default as OlyTabs } from './components/OlyTabs/OlyTabs.vue'
export { default as OlyTab } from './components/OlyTabs/OlyTab.vue'
export { default as OlyFlag } from './components/OlyFlag/OlyFlag.vue'
export { default as OlyFuelIcon } from './components/OlyFuelIcon/OlyFuelIcon.vue'
export { default as OlyPanel } from './components/OlyPanel/OlyPanel.vue'
export { default as OlyPanelBar } from './components/OlyPanelBar/OlyPanelBar.vue'
export { default as OlyPanelBars } from './components/OlyPanelBar/OlyPanelBars.vue'
export { default as OlyPanelTitle } from './components/OlyPanel/OlyPanelTitle.vue'
export { default as OlyPanelTabContainer } from './components/OlyPanelTabContainer/OlyPanelTabContainer.vue'
export { default as OlyPanelTabTitle } from './components/OlyPanelTabContainer/OlyPanelTabTitle.vue'
export { default as OlyPanelTransition } from './components/OlyPanelTransition/OlyPanelTransition.vue'

export { default as MyFavourites } from './components/MyFavourites/MyFavourites.vue'

export { default as OlyScrollContainer } from './components/OlyScrollContainer/OlyScrollContainer.vue'
export { default as OlyScrollContainerButton } from './components/OlyScrollContainer/OlyScrollContainerButton.vue'

// Transitions
export { default as TransitionGrow } from './components/Transitions/TransitionGrow.vue'

// Directives
export { clickOutside } from './directives/clickOutside'
