<script lang="ts">
import { SlotsType, defineComponent } from 'vue'
/**
  * Use `OlyLabel` To insert a Label.
**/
export default defineComponent({
  name: 'OlyLabel',
  props: {
    /** The id of a labelable form-related element in the same document as the <label> element. */
    targetElementId: {
      type: String,
      required: false,
      default: ''
    }
  },
  slots: Object as SlotsType<{
    default: { content: string }
  }>
})
</script>

<template>
  <label :for="targetElementId">
    <slot />
  </label>
</template>
