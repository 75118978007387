<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

const props = defineProps({
  maxWidth: {
    type: Number,
    required: false,
    default: 800
  },
  dropShadow: {
    type: Boolean,
    required: false,
    default: false
  },
  cssClass: {
    type: String,
    required: false,
    default: ''
  }
})

const popupWidth = props.maxWidth + 'px'

const emit = defineEmits(['close'])

const close = (): void => {
  emit('close')
}

const closeOnEsc = (evt: KeyboardEvent) => {
  if (evt.code === 'Escape') {
    close()
  }
}

onMounted(() => {
  document.addEventListener('keyup', closeOnEsc)
})

onUnmounted(() => {
  document.removeEventListener('keyup', closeOnEsc)
})
</script>

<template>
  <teleport to="body">
    <div
      class="cover"
      :class="cssClass"
    >
      <div
        class="cover__outside"
        @click="close"
      />
      <div class="popup__container">
        <div
          :class="{ 'popup--drop-shadow': dropShadow }"
          class="popup"
        >
          <div class="popup__head">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="close"
            >
              <path
                d="M6.73301 15L10.333 11.4L13.933 15L15.333 13.6L11.733 10L15.333 6.4L13.933 5L10.333 8.6L6.73301 5L5.33301 6.4L8.93301 10L5.33301 13.6L6.73301 15ZM10.333 20C8.94967 20 7.64967 19.7375 6.43301 19.2125C5.21634 18.6875 4.15801 17.975 3.25801 17.075C2.35801 16.175 1.64551 15.1167 1.12051 13.9C0.595508 12.6833 0.333008 11.3833 0.333008 10C0.333008 8.61667 0.595508 7.31667 1.12051 6.1C1.64551 4.88333 2.35801 3.825 3.25801 2.925C4.15801 2.025 5.21634 1.3125 6.43301 0.7875C7.64967 0.2625 8.94967 0 10.333 0C11.7163 0 13.0163 0.2625 14.233 0.7875C15.4497 1.3125 16.508 2.025 17.408 2.925C18.308 3.825 19.0205 4.88333 19.5455 6.1C20.0705 7.31667 20.333 8.61667 20.333 10C20.333 11.3833 20.0705 12.6833 19.5455 13.9C19.0205 15.1167 18.308 16.175 17.408 17.075C16.508 17.975 15.4497 18.6875 14.233 19.2125C13.0163 19.7375 11.7163 20 10.333 20ZM10.333 18C12.5663 18 14.458 17.225 16.008 15.675C17.558 14.125 18.333 12.2333 18.333 10C18.333 7.76667 17.558 5.875 16.008 4.325C14.458 2.775 12.5663 2 10.333 2C8.09967 2 6.20801 2.775 4.65801 4.325C3.10801 5.875 2.33301 7.76667 2.33301 10C2.33301 12.2333 3.10801 14.125 4.65801 15.675C6.20801 17.225 8.09967 18 10.333 18Z"
                fill="#232323"
              />
            </svg>
          </div>
          <div class="popup__body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
$maxWidth: v-bind(popupWidth);

.cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset-inline-start: 0;
  top: 0;
  pointer-events: none;
  z-index: 100;

  &__outside {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    inset-inline-start: 0;
    top: 0;
    pointer-events: all;
  }
}

.popup__container {
  position: absolute;
  z-index: 2;
  inset-inline-start: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  width: calc(100% - 40px);
  max-width: $maxWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
}

[dir="rtl"] .popup__container {
  transform: translateX(50%);
}

.popup {
  --popup-background: #ffffff;
  --popup-text: #232323;

  overflow-y: auto;
  background-color: var(--popup-background);
  color: var(--popup-text);
  border-radius: var(--border-radius, 8px);
  padding: 10px;
  pointer-events: all;
  width: 100%;

  &__head {
    height: 20px;
    display: flex;
    justify-content: flex-end;

    & svg, path {
      fill: var(--popup-text);
    }

    &:hover {
      svg, div {
        cursor: pointer;
      }
    }
  }

  &--drop-shadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}

body.in-iframe .popup__container {
  top: 100px;
  transform: translate(-50%, 0)
}
</style>
