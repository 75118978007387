<script lang="ts" setup>
import OlyButtonV2 from '../OlyButton/OlyButtonV2.vue'
import OlyCornerTriangle from '../OlyCornerTriangle/OlyCornerTriangle.vue'
import OlyHoverContainer from '../OlyHoverContainer/OlyHoverContainer.vue'
import OlyIcon from '../OlyIcon/OlyIcon.vue'

defineProps({
  title: {
    type: String,
    required: false,
    default: ''
  },
  bartext: {
    type: String,
    required: false,
    default: ''
  },
  enableBarClick: {
    type: Boolean,
    required: false,
    default: false
  },
  enableButton: {
    type: Boolean,
    required: false,
    default: false
  },
  buttonText: {
    type: String,
    required: false,
    default: ''
  },
  buttonIconSrc: {
    type: String,
    required: false,
    default: ''
  },
  iconFillColor: {
    type: String,
    required: false,
    default: '#fff'
  },
  sticky: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['header-click'])

const onClick = () => {
  emit('header-click')
}

</script>

<template>
  <div
    class="section-header"
    :class="{ 'sticky': sticky }"
  >
    <p
      v-if="title"
      class="section-header__title"
    >
      {{ title }}
    </p>
    <div
      v-if="enableButton"
      class="section-header__bar-wrapper section-header__bar-wrapper--with-button"
    >
      <div class="section-header__bar">
        {{ bartext }}
        <slot />
      </div>
      <OlyHoverContainer
        is-active
        @click="onClick"
      >
        <OlyButtonV2
          :is-active="true"
          class="section-header__button"
        >
          <OlyIcon
            v-if="buttonIconSrc"
            :icon-url="buttonIconSrc"
            icon-color="#fff"
            :width="20"
            :height="20"
          />
          {{ buttonText }}
        </OlyButtonV2>
        <OlyCornerTriangle
          corner-position="bottomLeft"
          hover-corner-position="none"
        />
      </OlyHoverContainer>
    </div>

    <div
      v-else
      class="section-header__bar-wrapper section-header__bar-wrapper--without-button"
    >
      <div class="section-header__bar">
        {{ bartext }}
        <slot />
      </div>
      <OlyCornerTriangle
        corner-position="bottomLeft"
        hover-corner-position="topRight"
        :hover-corner-border-radius="10"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/breakpoints.scss';

.section-header {
  --backgroundColor: var(--main-color-3);
  --titleColor: var(--main-font-color-1);
  --bartextColor: var(--main-font-color-2);

  &.sticky {
    position: sticky;
    z-index: 10;
    top: 0;
    margin: 0 -10px;
    @include small() {
      position: initial;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: bold;
    color: var(--titleColor);
    margin: 5px;
  }
  &__bar-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    &--with-button {
      .section-header__bar :deep(svg) {
        margin-inline-end: 8px;
      }
      .corner-triangle {
        cursor: pointer;
      }
    }
    &--without-button {
      .hover-container {
        width: 100%;
      }
    }
  }
  &__bar {
    font-size: 16px;
    font-weight: 400;
    line-height:40px;
    background-color: var(--backgroundColor);
    color:var(--bartextColor);
    padding-inline-start: 10px;
    width: 100%;
    height: 40px;
    line-height: 1em;
    display: flex;
    align-items: center;
  }
  &__button {
    --active-background: var(--main-togglebutton-on-background-color);
    --active-text: var(--main-togglebutton-on-font-color);
    white-space: nowrap;
    margin: 0;
    border-radius: 0;
  }
}

.hover-container {
  display: flex;
}

.corner-triangle {
  display: none;

  @include medium() {
    display: grid;
  }
}

</style>
