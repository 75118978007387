<template>
  <transition
    mode="out-in"
    name="grow"
  >
    <slot />
  </transition>
</template>

<style>
  .grow-enter-active
  {
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
    transform-origin: top left;
  }

  .grow-leave-active
  {
    display: none;
  }

  .grow-enter-from {
    transform: scale(0);
    opacity: 0;
  }

  .grow-enter-to
  {
    transform: scale(1);
    opacity: 1;
  }
</style>
