<script setup lang="ts">

</script>

<template>
  <div />
</template>

<style scoped lang="scss">
div {
  width: 60px;
  height: 100%;

  border-inline-start: 1px solid color-mix(in srgb, var(--panel-bar-border, #ff3c00) 75%, var(--panel-bar-border-shade, #ffffff));
  background-color: color-mix(in srgb, var(--panel-bar-background, #ffffff) 75%, var(--panel-bar-background-shade, #232323));
  transform-origin: top;
  transform: skew(340deg, 360deg);

  [dir="rtl"] & {
    transform: skew(-340deg, 360deg);
  }
}
</style>
