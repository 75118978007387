<script setup lang="ts">
import { translator } from '../../i18n/i18n'

export interface Props {
    textColor?: string,
    backgroundColor?: string,
    borderRadius?: number,
    padding?: number,
    fontSize?: number
  }

withDefaults(defineProps<Props>(), {
  textColor: '#000000',
  backgroundColor: '#fbb801',
  borderRadius: 10,
  padding: 20,
  fontSize: 1.5
})

const { t } = translator.useI18n()

</script>

<template>
  <div class="contact-us">
    <div class="contact-us__container">
      <slot>
        <i18n-t
          keypath="contact-us.text"
          tag="span"
        >
          <template #link>
            <a
              href="https://www.olyslager.com/contact-us/"
              target="_blank"
            >{{ t('contact-us.contact-us') }}</a>
          </template>
        </i18n-t>
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .contact-us {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: calc(v-bind(fontSize) * 1rem);
    color: v-bind(textColor);

    :deep(a) {
      color: v-bind(textColor);
    }

    &__container {
      padding: calc(v-bind(padding) * 1px);
      border-radius: calc(v-bind(borderRadius) * 1px);
      background-color: v-bind(backgroundColor);
    }
  }
</style>
