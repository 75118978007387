import { ref } from 'vue'
import { debug } from 'debug'

import { QueryDocument, QueryError, Variables, dataClient } from '@olyslager/api-client'

const debugUseData = debug('shared-components:useData')

export default function useData () {
  const loading = ref(false)
  const loadingSlow = ref(false)
  const results = ref()
  const errors = ref<QueryError[] | undefined>()
  const statusCode = ref<number | undefined>()

  const execute = async<T> (query: QueryDocument, variables?: Variables) => {
    debugUseData('Executing query')
    loading.value = true
    loadingSlow.value = false
    results.value = null
    errors.value = undefined

    const slowLoad = setTimeout(() => {
      loadingSlow.value = true
    }, 500)

    const data = await dataClient.get<T>(query, variables)

    results.value = data.data
    errors.value = data.errors
    statusCode.value = data.statusCode

    debugUseData('Executed query %O', results.value)

    clearTimeout(slowLoad)
    loading.value = false
    loadingSlow.value = false

    return data.data as T
  }
  return { loading, loadingSlow, results, errors, statusCode, execute }
}
