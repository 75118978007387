<script setup lang="ts">
import { RendererNode, VNode, onBeforeMount, provide, reactive, useSlots, watch } from 'vue'

import { TabsProvider } from './types'

import OlyTab from './OlyTab.vue'

const emit = defineEmits<{(event: 'tabSelected', index: number): void }>()

const slots = useSlots()

const tabsState: TabsProvider = reactive({
  selectedIndex: 0,
  tabs: [] as VNode<RendererNode>[],
  count: 0
})

provide('TabsProvider', tabsState)

const selectTab = (i: number) => {
  tabsState.selectedIndex = i
}

watch(() => tabsState.selectedIndex, (selectedIndex) => {
  emit('tabSelected', selectedIndex)
})

onBeforeMount(() => {
  if (slots.default) {
    tabsState.tabs = slots.default().filter((child) => (child.type as typeof OlyTab).__name === 'OlyTab')
  }
})
</script>

<template>
  <div class="oly-tabs">
    <ul class="tabs">
      <li
        v-for="(tab, index) in tabsState.tabs"
        :key="index"
        :class="{ 'selected': index === tabsState.selectedIndex }"
        class="tabs__header"
        @click="selectTab(index)"
      >
        {{ tab.props?.title ? tab.props.title : 'Tab ' + index }}
      </li>
    </ul>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.oly-tabs {
  --selectedColor: var(--main-togglebutton-on-background-color, #FBB801);
  --textColor: var(--main-font-color-1, #000000);
  --fontSize: 1.1rem;
  --fontWeight: normal;

  .tabs {
    padding-block-end: 24px;

    &__header {
      display: inline;
      cursor: pointer;
      font-size: var(--fontSize);
      font-weight: var(--fontWeight);
      padding-inline-start: .5rem;
      padding-inline-end: 1.5rem;
      padding-bottom: 6px;
      color: var(--textColor);
      border-bottom: 2px solid var(--textColor);
      transition: all 0.3s ease-in-out;

      &:hover {
        border-bottom: 2px solid var(--selectedColor);
      }

      &.selected {
        border-bottom: 2px solid var(--selectedColor);
      }
    }
  }
}
</style>
