<script setup lang="ts">
import { computed } from 'vue'

import OlyIcon from '@/components/OlyIcon/OlyIcon.vue'

const props = defineProps({
  country: {
    type: String,
    required: true
  },
  square: {
    type: Boolean,
    required: false,
    default: false
  },
  rounded: {
    type: Boolean,
    required: false,
    default: false
  },
  shadow: {
    type: Boolean,
    required: false,
    default: false
  },
  borderRadius: {
    type: Number,
    required: false,
    default: 0
  }
})

const cdnUrl = import.meta.env.VITE_NETLIFY_CDN_URL

const flagAspectRatio = computed(() => {
  if (props.square) {
    return '1x1'
  } else {
    return '4x3'
  }
})

</script>

<template>
  <OlyIcon
    :icon-url="`${cdnUrl}/flags/${flagAspectRatio}/${country}.svg`"
    class="flag"
    preserveAspectRatio="none"
    :class="[
      { square: square || rounded,
        rounded,
        shadow }
    ]"
  />
</template>

<style scoped lang="scss">
  .flag {
    width: 1.333333em;
    border-radius: calc(1px * v-bind(borderRadius));

    &.square {
      width: 1em;
      height: 1em;
    }

    &.rounded {
      border-radius: 50%;
    }

    &.shadow {
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
</style>
