<script lang="ts" setup>
import { PropType, onMounted, ref } from 'vue'
import VueSelect from 'vue-next-select'

import { useScrollbar } from '../../composables'

import accentFold from './accentFold'

const props = defineProps({
  /**
     * The data.
     * This can be an array of strings or objects
     */
  options: {
    type: Object as PropType<object[] | string[]>,
    required: true
  },
  /**
     * The name of the property of an option to use as a value, this can be a string or a function returning a string
     *
     * Not needed when options is an array of strings
     */
  valueby: {
    type: [String, Function],
    required: false,
    default: null
  },
  /**
     * The name of the property of an option to use as a label, this can be a string or a function returning a string
     *
     * Not needed when options is an array of strings
     */
  labelby: {
    type: [String, Function],
    required: false,
    default: null
  },
  /**
     * The modelValue used by v-model
     */
  modelValue: {
    type: [String, Number, Object],
    required: false,
    default: null
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
     * The text shown when nothing is selected
     */
  placeholder: {
    type: String,
    required: false,
    default: null
  },
  searchable: {
    type: Boolean,
    required: false,
    default: false
  },
  /**
     * The text shown in the textbox when searchable is true
     */
  searchPlaceholder: {
    type: String,
    required: false,
    default: null
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  useCustomScrollbar: {
    type: Boolean,
    required: false,
    default: true
  }
})

const emit = defineEmits(['selected', 'update:modelValue'])

const olyselect = ref<HTMLElement>()
// Model used internally by VueSelect, without the v-model stuff breaks.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const model = ref<unknown>(null)

const visibleOptions = ref<(object | string)[]>([])
const searchInput = ref<string>()

defineExpose({ visibleOptions })

let updateScrollbar: (resetScroll?: boolean | undefined) => void
if (props.useCustomScrollbar) {
  const scrollbar = useScrollbar(olyselect, 'ul.vue-dropdown', {
    wheelSpeed: 1,
    wheelPropagation: false,
    minScrollbarLength: 30,
    suppressScrollX: true
  })
  updateScrollbar = scrollbar.updateScrollbar
}

onMounted(() => {
  visibleOptions.value = props.options
})

// TODO: make composable of the searchable logic
const handleSearchInput = (event: { target: { value: string } }) => {
  searchInput.value = event.target.value
  // show all options when you clear the search text
  if (searchInput.value === '') {
    visibleOptions.value = props.options
  } else {
    const currentSearchInput = searchInput.value
    const foundOptions = filterOptions(props.options, currentSearchInput.toLowerCase())
    visibleOptions.value = foundOptions
  }

  if (props.useCustomScrollbar) {
    updateScrollbar(true)
  }
}

// Filter the options
function filterOptions (options: (string | object)[], filterValue: string) {
  return options.filter(option => {
    let label: string
    if (typeof props.labelby === 'function') {
      label = props.labelby(option).toLowerCase()
    } else {
      label = option[props.labelby as string].toLowerCase()
    }
    return (
      label.indexOf(filterValue) !== -1 ||
          accentFold(label).indexOf(filterValue) !== -1)
  })
}

function handleSelected (selected: object) {
  emit('selected', selected)
}

const handleOpened = function () {
  if (props.useCustomScrollbar) {
    updateScrollbar(true)
  }
}
</script>

<template>
  <div
    ref="olyselect"
    class="olyselect"
    :class="{'has-custom-scrollbar': useCustomScrollbar, searchable}"
  >
    <VueSelect
      :model-value="modelValue"
      :options="options"
      :label-by="labelby"
      :value-by="valueby"
      :placeholder="placeholder"
      :loading="loading"
      close-on-select
      clear-on-select
      open-direction="bottom"
      :disabled="disabled"
      :max-height="500"
      :min="1"
      :search-placeholder="searchPlaceholder"
      :searchable="searchable"
      :visible-options="visibleOptions"
      @update:model-value="$emit('update:modelValue', $event)"
      @selected="handleSelected"
      @search:input="handleSearchInput"
      @opened="handleOpened"
    >
      <template #dropdown-item="{ option }">
        <slot
          name="itemtemplate"
          :slot-option="option"
        />
      </template>
      <template #toggle="{ toggle }">
        <div
          class="arrowbox"
          @click="toggle"
          @mousedown.prevent.stop
        >
          <span class="icon arrow-downward" />
        </div>
      </template>
    </VueSelect>
  </div>
</template>
<style src='vue-next-select/dist/index.min.css'>
</style>
<style lang="scss">
  .olyselect {
    --textColor: var(--main-font-color-4);
    --backgroundColor: var(--main-input-color-1);
    --borderColor: var(--main-input-color-3);
    --dropdownHoverColor: var(--main-input-color-4);
    --arrowBoxBackgroundColor: var(--main-input-color-2);
  }
  .olyselect .vue-select-header {
    cursor: pointer;
  }

  .olyselect.searchable  {
    cursor: text;
    .vue-select[data-is-focusing=false][aria-disabled=false]  .vue-input input, input[readonly] {
      background-color:var(--backgroundColor);
      cursor: text;
    }
    .vue-select[data-is-focusing=true] .vue-input input, input[readonly] {
      background-color:var(--backgroundColor);
      cursor: text;
    }
    .vue-select-header {
      cursor: text;
    }
  }

  .vue-select[data-is-focusing=false][aria-disabled=false] .vue-input input, input[readonly]{
    cursor: pointer;
  }

  .olyselect .vue-dropdown .vue-dropdown-item.selected:hover {
    cursor: pointer;
  }

  .vue-select[data-is-focusing=true]:not([data-visible-length='0']) {
    border-radius: 5px;
  }
  .vue-select {
    background-color: var(--backgroundColor);
    border-radius: 5px;
    border: 1px solid var(--borderColor);
    margin-bottom: 3px;
    width: inherit;
    &.disabled {
      background-color: #d3d3d3;
      .vue-select-header {
        .vue-input {
          ::placeholder {
            color: #aaa;
          }
        }
      }
    }
    &.direction-bottom .vue-dropdown {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .vue-select-header {
      .vue-input {
        height: 40px;
        line-height: 40px;
        input {
          font-size: 14px;
          cursor: inherit;
          height: 100%;
        }
        ::placeholder {
          color: var(--textColor)
        }
      }
      .arrowbox {
        background-color: var(--arrowBoxBackgroundColor);
        border-radius: 4px;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        width: 32px;
        margin: 4px;
        cursor: pointer;
        .arrow-downward {
          border-color: var(--backgroundColor) transparent transparent;
          border-width: 5px 5px 0;
          position: absolute;
          top: 15px;
          right: 7px;
        }
      }
    }
    .vue-dropdown {
      width: 100%;
      margin-top: 4px;
      border: 1px solid var(--borderColor);
      border-radius: 5px;
      max-height: 500px;
      box-sizing: content-box;
      background-color: var(--backgroundColor);
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
      z-index: 100;
    }
    .vue-dropdown-item {
      color: var(--textColor);
      display: flex;
      justify-content: flex-start;
      a, a:visited {
        color: var(--textColor);
      }
      &.highlighted {
        background-color: var(--dropdownHoverColor);
      }
    }
  }
  .has-custom-scrollbar .vue-dropdown {
    border: 1px solid var(--borderColor); // The custom scrollbar doesn't like borders
    inset-inline-start: 0px;
  }
</style>
