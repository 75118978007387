import { Ref, onMounted, onUnmounted } from 'vue'
import { debug } from 'debug'
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const debugAddScrollbar = debug('shared-components:useScrollbar')

export default function useScrollbar (element: Ref<HTMLElement | undefined>, cssSelector?: string, options?: PerfectScrollbar.Options) {
  let ps: PerfectScrollbar | null
  let container: HTMLElement | undefined

  function addScrollbar () {
    if (!element.value) {
      debugAddScrollbar('No element!')
      return
    }

    if (cssSelector) {
      container = element.value.querySelector(cssSelector) as HTMLElement | undefined
    } else {
      container = element.value
    }

    if (!container) {
      debugAddScrollbar('No container!')
      return
    }

    ps = new PerfectScrollbar(container, options || {
      wheelSpeed: 1,
      wheelPropagation: false,
      minScrollbarLength: 30,
      suppressScrollX: true
    })
  }

  const updateScrollbar = function (resetScroll?: boolean) {
    if (container && resetScroll) {
      container.scrollTop = 0
    }
    ps?.update()
  }

  onMounted(() => {
    addScrollbar()
  })

  onUnmounted(() => {
    ps?.destroy()
    ps = null
  })

  return { updateScrollbar }
}
