<script lang="ts">
import { SlotsType, defineComponent } from 'vue'

export default defineComponent({
  name: 'OlyTileContainer',
  slots: Object as SlotsType<{
    default: string
  }>
})
</script>

<template>
  <div class="tile-container">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.tile-container {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

</style>
