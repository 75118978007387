<script setup lang="ts">
import { ref, watch } from 'vue'

import OlyPanelTabTitle from './OlyPanelTabTitle.vue'

const props = defineProps({
  tabs: {
    type: Array<string>,
    required: true,
    default: []
  },
  defaultTab: {
    type: Number,
    required: false,
    default: 0
  },
  showBorderRadius: {
    type: Boolean,
    required: false,
    default: true
  },
  bordered: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits<{
  tabChanged: [tabIndex: number]
}>()

const activeTab = ref(props.defaultTab)

watch(() => props.tabs, () => {
  activeTab.value = props.defaultTab
})

const tabClick = (index: number) => {
  activeTab.value = index
  emit('tabChanged', index)
}

screen.orientation.addEventListener('change', () => {
  activeTab.value = props.defaultTab
})

</script>

<template>
  <div>
    <header>
      <OlyPanelTabTitle
        v-for="(tab, index) in tabs"
        :key="index"
        :disabled="index !== activeTab"
        :show-border-radius="showBorderRadius"
        :bordered="bordered"
        @click="tabClick(index)"
      >
        {{ tab }}
      </OlyPanelTabTitle>
    </header>
    <article>
      <slot :active-tab="activeTab" />
    </article>
  </div>
</template>

<style scoped lang="scss">
header {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

article :slotted(.panel) article {
  border-start-start-radius: 0px;
}
</style>
