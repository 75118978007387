<script lang="ts">
import { SlotsType, defineComponent, ref } from 'vue'

import OlyIcon from '../OlyIcon/OlyIcon.vue'

export default defineComponent({
  name: 'OlyCollapse',
  components: { OlyIcon },
  props: {
    bartext: {
      type: String,
      required: true
    },
    iconUrl: {
      type: String,
      required: false,
      default: ''
    },
    initialOpen: {
      type: Boolean,
      required: false,
      default: false
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  slots: Object as SlotsType<{
    default: string
  }>,
  setup (props) {
    const isOpen = ref<boolean>(props.initialOpen)

    const toggle = (): void => {
      isOpen.value = !isOpen.value
    }

    // TODO: revert to htmlelement parameter type when types are fixed
    // https://github.com/vuejs/language-tools/issues/2749
    const beforeEnter = function (el: Element): void {
      (el as HTMLElement).style.height = '0'
    }
    const enter = function (el: Element): void {
      (el as HTMLElement).style.height = el.scrollHeight + 'px'
    }
    const afterEnter = function (el: Element): void {
      (el as HTMLElement).style.height = 'auto'
    }
    const beforeLeave = function (el: Element): void {
      (el as HTMLElement).style.height = el.scrollHeight + 'px'
    }
    const leave = function (el: Element): void {
      (el as HTMLElement).style.height = '0'
    }

    return {
      toggle,
      isOpen,
      beforeEnter,
      enter,
      afterEnter,
      beforeLeave,
      leave
    }
  }
})
</script>

<template>
  <div
    :class="{ 'collapse--open': isOpen }"
    class="collapse"
  >
    <div
      class="collapse__head"
      :class="{ 'sticky': sticky }"
      @click="toggle"
    >
      <div
        v-if="iconUrl"
        class="collapse__head-icon"
      >
        <OlyIcon
          :icon-url="iconUrl"
          :height="32"
          :width="32"
          icon-color="var(--main-font-color-2, #fbb801)"
        />
      </div>
      {{ bartext }}
      <div class="collapse__head-toggle" />
    </div>
    <transition
      name="collapse"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-show="isOpen"
        class="collapse__body"
      >
        <div class="collapse__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
@import '../../styles/breakpoints.scss';

  .collapse {
    --headerBackgroundColor: var(--main-color-3, #4f4d4e);
    --contentBackgroundColor: var(--main-input-color-1, #ffffff);
    --textColor: var(--main-font-color-2, #fbb801);
    --iconColor: var(--main-font-color-2, #fbb801);

    margin-bottom: 10px;

    &__head {
      cursor: pointer;
      height: 38px;
      background-color: var(--headerBackgroundColor);
      color: var(--textColor);
      padding: 3px;
      display: flex;
      align-items: center;
      position: relative;

      &.sticky {
        position: sticky;
        z-index: 10;
        top: 0;
        margin: 0 -10px;
        @include small() {
          position: relative;
        }
      }

      &-icon {
        margin-inline-end: 8px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-toggle {
        width: 32px;
        height: 32px;
        position: absolute;
        inset-inline-end: 3px;
        top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        line-height: 1;
        font-weight: 700;
        color: var(--iconColor);

        &:after {
          content: '+';
          // little correction for esthetics
          position: relative;
          top: -1px;
        }
      }
    }

    &__body {
      overflow: hidden;
      transition: 300ms ease-out;
    }

    &__content {
      overflow-wrap: break-word;
      padding: 20px;
      background-color: var(--contentBackgroundColor);
    }

    &--open {

      .collapse__head {

        &-toggle {

          &:after {
            content: '-';
          }
        }
      }
    }
  }
</style>
