<script setup lang="ts">
import { inject, onBeforeMount, ref, watch } from 'vue'

import { TabsProvider } from './types'

defineProps<{
  title?: string
}>()

const index = ref(0)
const isActive = ref(false)
const tabs: TabsProvider | undefined = inject('TabsProvider')

watch(() => tabs, () => {
  if (!tabs) return
  isActive.value = index.value === tabs.selectedIndex
}, { deep: true, immediate: true }
)

onBeforeMount(() => {
  if (!tabs) {
    isActive.value = false
    return
  }

  index.value = tabs.count
  tabs.count++
  isActive.value = index.value === tabs.selectedIndex
})
</script>

<template>
  <div
    v-if="isActive"
    class="tab"
  >
    <slot />
  </div>
</template>
