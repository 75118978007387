<script setup lang="ts">
import { computed } from 'vue'

import OlyIconV2 from '../OlyIconV2/OlyIconV2.vue'

const props = defineProps({
  isActive: {
    type: Boolean,
    required: false,
    default: false
  },
  border: {
    type: Boolean,
    required: false,
    default: false
  },
  iconPrefixSrc: {
    type: String,
    required: false,
    default: null
  },
  iconPrefixInline: {
    type: Boolean,
    required: false,
    default: false
  },
  iconPrefixSize: {
    type: Number,
    required: false,
    default: 24
  },
  iconSuffixSrc: {
    type: String,
    required: false,
    default: null
  },
  iconSuffixInline: {
    type: Boolean,
    required: false,
    default: false
  },
  iconSuffixSize: {
    type: Number,
    required: false,
    default: 24
  },
  slim: {
    type: Boolean,
    required: false,
    default: false
  },
  scaleIconOnHover: {
    type: Boolean,
    required: false,
    default: false
  }
})

const slots = defineSlots<{
  default: string,
  iconPrefix: string,
  iconSuffix: string
}>()

const hasIconPrefixSlot = computed(() => !!slots.iconPrefix)
const hasIconSuffixSlot = computed(() => !!slots.iconSuffix)

const hasPrefixInlineIcon = computed(() => (props.iconPrefixSrc && props.iconPrefixInline) || hasIconPrefixSlot.value)
const hasSuffixInlineIcon = computed(() => (props.iconSuffixSrc && props.iconSuffixInline) || hasIconSuffixSlot.value)

</script>

<template>
  <div
    class="button"
    :class="{ 'button--active': isActive, 'button--border': border, 'button--slim': slim, 'button--scale-icon-on-hover': scaleIconOnHover }"
  >
    <div class="button__content">
      <div
        v-if="hasIconPrefixSlot || iconPrefixSrc"
        class="content__icon"
        :class="{ 'content__icon--inline': hasPrefixInlineIcon, 'content__icon--url': !hasPrefixInlineIcon }"
      >
        <slot name="iconPrefix">
          <OlyIconV2
            v-if="iconPrefixSrc"
            class="icon"
            :src="iconPrefixSrc"
            :size="iconPrefixSize"
            :inline="iconPrefixInline"
          />
        </slot>
      </div>
      <slot />
      <div
        v-if="hasIconSuffixSlot || iconSuffixSrc"
        class="content__icon"
        :class="{ 'content__icon--inline': hasSuffixInlineIcon, 'content__icon--url': !hasSuffixInlineIcon }"
      >
        <slot name="iconSuffix">
          <OlyIconV2
            v-if="iconSuffixSrc"
            class="icon"
            :src="iconSuffixSrc"
            :size="iconSuffixSize"
            :inline="iconSuffixInline"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.button {
  $block: &;

  --border: #ff3c00;
  --background: #ffffff00;
  --text: #232323;
  --active-border: #ffffff00;
  --active-background: #ff3c00;
  --active-text: #ffffff;

  display: inline-flex;
  cursor: pointer;
  border: 0;
  border-radius: var(--border-radius, 8px);
  color: var(--text);
  background-color: var(--background);
  white-space: normal;
  font-size: inherit;
  line-height: inherit;
  transition: 300ms ease;
  padding: 8px;
  &--slim {
    padding: 6px 8px;
  }

  &--active, &:hover {
    background-color: var(--active-background);
    color: var(--active-text);
  }

  &--border {
    outline: 1px solid var(--border);
    &:hover, &#{$block}--active {
      outline: 1px solid var(--active-border);
    }
  }

  &--scale-icon-on-hover:hover {
    .content__icon {
      transform: scale(1.1);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    #{$block}--slim & {
      gap: 4px;
    }

    .content {
      &__icon {
        display: flex;
        .icon {
          transition: all 300ms ease;
        }
        &--url .icon {
          background-color: var(--text);
          #{$block}:hover &, #{$block}--active & {
            background-color: var(--active-text);
          }
        }
        &--inline {
          .icon, :slotted(svg) {
            fill: var(--text);
            #{$block}:hover &, #{$block}--active & {
              fill: var(--active-text);
            }
          }
        }
      }
    }
  }
}
</style>
