import { debug } from 'debug'
import { I18n, I18nOptions, UseI18nOptions, createI18n as vueCreateI18n, useI18n as vueUseI18n } from 'vue-i18n'

import { Locale, Translations } from '@olyslager/customer-configuration'
import { languages, translations } from '@olyslager/global-utilities'

export declare type TranslatorOptions = {
  debuggerProjectName: string;
  i18nOptions?: I18nOptions;
}

let i18nInstance: I18n<Translations>

const createI18n = (options?: TranslatorOptions): I18n<Translations> => {
  const projectName = options?.debuggerProjectName ?? 'unknown'

  const debugCreateI18n = debug(`${projectName}:shared-components:i18n:createI18n`)

  debugCreateI18n('Creating i18n instance')

  const currentLanguage = languages.getCurrentLanguage().code2
  const allTranslations = translations.getTranslations()

  const i18n = vueCreateI18n({
    legacy: false,
    locale: currentLanguage,
    fallbackLocale: 'en',
    messages: { [currentLanguage]: allTranslations },
    ...options?.i18nOptions
  })

  i18nInstance = i18n

  debugCreateI18n('Finished creating i18n instance')

  return i18n
}

const useI18n = (options?: UseI18nOptions) => {
  return vueUseI18n(options)
}

function deepMerge (source, mergeMe) {
  for (const key in mergeMe) {
    if (Object.prototype.hasOwnProperty.call(mergeMe, key)) {
      if (mergeMe[key] instanceof Object && source[key] instanceof Object) {
        source[key] = deepMerge(source[key], mergeMe[key])
      } else {
        source[key] = mergeMe[key]
      }
    }
  }
  return source
}

const reloadTranslations = async (locale: Locale, customerTranslations: Translations) => {
  if (customerTranslations && customerTranslations[locale]) {
    const merged = deepMerge(i18nInstance.global.messages.value[locale], customerTranslations[locale])
    i18nInstance.global.setLocaleMessage(locale, merged)
  }
}

export const translator = {
  createI18n,
  useI18n,
  reloadTranslations
}
