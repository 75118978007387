import { debug } from 'debug'

import { DefaultColor, SiteConfiguration, defaults } from '@olyslager/customer-configuration'
import { eventEmitter, siteConfigurationCache } from '@olyslager/global-utilities'
const debugUseCreateFrontendCssVars = debug('shared-components:useCreateFrontendCssVars')

const update = (options: { frontendName: string; colorMap?: Map<string, string>; colorMapForRoot?: Map<string, string>; }) => {
  debugUseCreateFrontendCssVars('Updating css vars for frontend', options.frontendName)

  const updatedSiteConfiguration: SiteConfiguration = siteConfigurationCache.get()

  if (updatedSiteConfiguration) {
    const rootColors = { ...defaults.colors, ...updatedSiteConfiguration.colors }
    const frontend = updatedSiteConfiguration.frontends.find(f => f.name === options.frontendName)

    if (frontend) {
      const cssVars: string[] = []
      const cssVarsForRoot: string[] = []
      const frontendColors = frontend.colors || {}
      options.colorMap?.forEach((defaultColor, colorName) => {
        cssVars.push(`--${colorName}: ${frontendColors[colorName] || rootColors[defaultColor] || defaultColor};`)
      })
      options.colorMapForRoot?.forEach((defaultColor, color) => cssVarsForRoot.push(`--${color}: ${frontendColors[color] || rootColors[defaultColor] || defaultColor};`))

      if (cssVars.length > 0 || cssVarsForRoot.length > 0) {
        const id = 'styles:' + options.frontendName
        const style = document.getElementById(id) || document.createElement('style')
        style.id = id
        style.textContent = ''
        if (cssVarsForRoot.length > 0) {
          style.textContent += `:root { ${cssVarsForRoot.join('')} }`
        }
        if (cssVars.length > 0) {
          style.textContent += `div[id="single-spa-application:${options.frontendName}"] { ${cssVars.join('')} }`
        }
        document.head.appendChild(style)
        debugUseCreateFrontendCssVars('Css vars', style.textContent)
        debugUseCreateFrontendCssVars('Style element with css vars added for frontend', options.frontendName)
      } else {
        debugUseCreateFrontendCssVars('No css vars to add')
      }
    }
  }
}

export default function useCreateFrontendCssVars (options: { frontendName: string; colorMap?: Map<string, DefaultColor> | Map<string, DefaultColor | string>; colorMapForRoot?: Map<string, DefaultColor> | Map<string, DefaultColor | string>; }) {
  debugUseCreateFrontendCssVars('Monitoring refeshFrontend event for frontend to create css vars', options)
  eventEmitter.on('refreshFrontend', (event) => {
    if (event.frontendName === 'all' || event.frontendName === options.frontendName) {
      update(options)
    }
  })

  update(options)
}
