<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  toggled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['toggle'])

const toggleState = ref(props.toggled)
const id = computed(() => {
  return props.name.replace(/ /g, '').toLowerCase()
})

const toggle = () => {
  if (props.disabled) return
  toggleState.value = !toggleState.value
  emit('toggle', toggleState.value)
}
</script>

<template>
  <div
    :class="{
      'oly-toggle--is-disabled': disabled,
    }"
    class="oly-toggle"
  >
    <input
      :id="id"
      v-model="toggleState"
      :disabled="disabled"
      :name="name"
      class="oly-toggle__input"
      type="checkbox"
      @click="toggle"
    >
    <span
      :aria-checked="toggleState"
      :aria-disabled="disabled"
      :aria-labelledby="`${id}-label`"
      :aria-readonly="disabled"
      class="oly-toggle__content"
      :class="{'oly-toggle__content--active': toggleState}"
      role="checkbox"
      @click="toggle"
    />
    <label
      :id="`${id}-label`"
      :for="id"
      class="oly-toggle__label"
    >
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.oly-toggle {
  --activeColor: #FBB801;
  --textColor: #d6d6d6;
  --fontSize: 16px;
  --fontWeight: normal;
  --size: 3.5em;

  $self: &;
  $toggle-spacing: 4px;
  align-items: center;
  display: flex;
  margin: 0 -5px .5rem 0;

  > * {
    cursor: pointer;
    margin-inline: 5px;
  }

  &__label {
    user-select: none;
    font-weight: var(--fontWeight);
    font-size: var(--fontSize);
    color: var(--textColor);

    #{$self}--is-disabled & {
      cursor: not-allowed;
    }
  }

  &__input {
    display: none;

    &:checked {
      & + #{$self}__content {
        &:after {
          inset-inline-start: calc(50% + #{$toggle-spacing});
        }
      }
    }
  }

  &__content {
    background: #F0F0F0;
    border-radius: calc(var(--size) / 2);
    box-sizing: border-box;
    height: calc(var(--size) / 2);
    outline: 0;
    overflow: hidden;
    padding: $toggle-spacing;
    transition: background-color .4s ease;
    width: var(--size);
    will-change: background-color;
    margin-inline-start: 0;

    &--active {
      background-color: var(--activeColor);
    }

    &:after {
      background: white;
      border-radius: 50%;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
      content: '';
      display: block;
      height: 100%;
      inset-inline-start: 0;
      position: relative;
      transition: inset-inline-start .2s ease;
      width: calc(50% - #{$toggle-spacing});
      will-change: inset-inline-start;
    }

    #{$self}--is-disabled & {
      cursor: not-allowed;
      opacity: 50%;
    }
  }
}
</style>
