<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

defineProps({
  enableAnimation: {
    type: Boolean,
    required: false,
    default: false
  },
  dropShadow: {
    type: Boolean,
    required: false,
    default: false
  }
})

defineSlots<{
  default: string
}>()

const emit = defineEmits(['close'])

const close = (): void => {
  emit('close')
}

const closeOnEsc = (evt: KeyboardEvent) => {
  if (evt.code === 'Escape') {
    close()
  }
}

onMounted(() => {
  document.addEventListener('keyup', closeOnEsc)
})

onUnmounted(() => {
  document.removeEventListener('keyup', closeOnEsc)
})

</script>

<template>
  <div class="cover">
    <div
      class="cover__outside"
      @click="close"
    />
    <div class="popup__container">
      <div
        :class="{'popup--animated': enableAnimation, 'popup--drop-shadow': dropShadow}"
        class="popup"
      >
        <div class="popup__head">
          <div
            class="popup__head-close"
            @click="close"
          />
        </div>
        <div class="popup__body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .cover {
    --maxWidth: 800px;

    position: fixed;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    top: 0;
    pointer-events: none;
    z-index: 100;

    &__outside {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      inset-inline-start: 0;
      top: 0;
      pointer-events: all;
    }
  }

  .popup__container {
    position: absolute;
    z-index: 2;
    inset-inline-start: 50%;
    top: 10vh;
    transform: translateX(-50%);
    max-height: 80vh;
    width: calc(100% - 40px);
    max-width: var(--maxWidth);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  [dir="rtl"] .popup__container {
    transform: translateX(50%);
  }

  .popup {
    --backgroundColor: var(--main-color-6, #4f4d4e);
    --textColor: var(--main-font-color-5, #fff);
    --iconColor: var(--main-togglebutton-off-background-color, #fff);
    --iconHoverColor: var(--main-togglebutton-on-background-color, red);
    --borderColor: var(--main-input-color-3, #fbb801);
    overflow-y: auto;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    border-radius: 10px;
    pointer-events: all;
    width: 100%;
    border: 5px solid transparent;

    &__head {
      // position: relative;
      height: 20px;
      display: flex;
      justify-content: flex-end;

      &-close {
        width: 32px;
        height: 32px;
        // position: absolute;
        display: flex;
        justify-content: flex-end;
        font-size: 28px;
        line-height: 1;
        font-weight: 700;
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--iconColor);

        &:after {
          content: '×';
          position: relative;
          top: -1px;
          padding-block: 4px;
          padding-inline: 8px;
        }
        &:hover {
          color: var(--iconHoverColor)
        }
      }
    }

    &__body {
      margin-top: 40px;
      padding: 0 20px 20px 20px;
    }

    &--animated {
      border: 5px solid var(--borderColor);
      animation: modal-border-fade-out .25s ease-in 0s forwards;
    }

    &--drop-shadow {
      box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.75);
    }
  }

  @keyframes modal-border-fade-out {
    100% {
        border-color: transparent;
    }
  }
</style>
