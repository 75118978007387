import { debug } from 'debug'

import { DataLayerObject } from '@olyslager/global-utilities'

import { TrackEventOptions } from './TrackEventOptions'

const debugUseGtm = debug('shared-components:useGtm')

export default function useGtm () {
  const trackEvent = async ({
    event,
    category = null,
    action = null,
    label = null,
    value = null,
    noninteraction = false,
    customDimensions = undefined,
    ...rest
  }: TrackEventOptions = {}) => {
    debugUseGtm('Tracking event %O', {
      event,
      category,
      action,
      label,
      value,
      noninteraction,
      customDimensions,
      ...rest
    })

    const dataLayer: DataLayerObject[] = (window.dataLayer = window.dataLayer ?? [])
    dataLayer.push({
      event: event ?? 'interaction',
      category,
      action,
      label,
      value,
      noninteraction,
      customDimensions,
      ...rest
    })
  }

  return { trackEvent }
}
