<script setup lang="ts">
import OlyIcon from '@/components/OlyIcon/OlyIcon.vue'

const props = defineProps({
  fuelId: {
    type: Number,
    required: true
  }
})

const cdnUrl = import.meta.env.VITE_NETLIFY_CDN_URL

interface FuelIcons {
  [fuelId: number]: string[]
}

const fuelIcons: FuelIcons = {
  1: ['petrol'],
  2: ['diesel'],
  4: ['cng'],
  7: ['gas'],
  8: ['gas', 'petrol'],
  10: ['ethanol', 'petrol'],
  11: ['cng', 'petrol'],
  12: ['diesel', 'gas'],
  13: ['gas', 'cng'],
  14: ['diesel', 'ethanol'],
  15: ['diesel', 'cng'],
  17: ['cng'],
  18: ['petrol', 'electric'],
  19: ['diesel', 'electric'],
  20: ['electric'],
  21: ['hydrogen'],
  22: ['hydrogen'],
  23: ['cng'],
  24: ['ethanol', 'petrol', 'electric']
}

</script>

<template>
  <div>
    <OlyIcon
      v-for="(fuelIcon, index) in fuelIcons[props.fuelId]"
      :key="index"
      :icon-url="`${cdnUrl}/fuels/${fuelIcon}.svg`"
    />
  </div>
</template>

<style scoped>

div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

svg {
  width: 40px;
  height: 40px;
}

</style>
