<script setup lang="ts">
import useDemo from '../../composables/useDemo'

defineProps({
  enableDemoFeatures: {
    type: Boolean,
    required: false,
    default: false
  }
})

const { demoFeaturesEnabled } = useDemo()
</script>

<template>
  <slot
    v-if="enableDemoFeatures || demoFeaturesEnabled"
    name="demo"
  />
  <slot v-else />
</template>
