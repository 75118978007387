import { Component, WritableComputedRef } from 'vue'
import { RouteRecordRaw, Router, createWebHistory, createRouter as vueCreateRouter } from 'vue-router'
import { debug } from 'debug'
import { I18n } from 'vue-i18n'

import { Translations } from '@olyslager/customer-configuration'
import { languages } from '@olyslager/global-utilities'

export declare type RouterOptions = {
  debuggerProjectName: string;
  rootPath: string;
  rootPathName?: string;
  rootComponent: Component;
  i18nInstance?: I18n<Translations>;
  routes?: RouteRecordRaw[];
  siblingRoutes?: RouteRecordRaw[];
}

function createRouter (options: RouterOptions): Router {
  const { debuggerProjectName, rootPath, rootPathName, rootComponent, i18nInstance, routes, siblingRoutes } = options

  const projectName = debuggerProjectName ?? 'unknown'

  const debugCreateRouter = debug(`${projectName}:shared-components:moaRouter:createRouter`)
  const debugBeforeEach = debug(`${projectName}:shared-components:moaRouter:beforeEach`)
  const debugBeforeEachNoLanguageFound = debug(`${projectName}:shared-components:moaRouter:beforeEach:noLanguageFound`)
  const debugBeforeEachRedirectLoop = debug(`${projectName}:shared-components:moaRouter:beforeEach:redirectLoop`)

  debugCreateRouter(`Creating router with root path '${rootPath}', root component '${rootComponent.name}' and routes %O`, routes)

  const currentLanguage = languages.getCurrentLanguage()

  const router = vueCreateRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/',
        redirect: '/' + currentLanguage.code2
      },
      {
        path: rootPath,
        name: rootPathName,
        component: rootComponent,
        children: routes
      },
      ...(siblingRoutes || [])
    ]
  })

  router.beforeEach((to, from, next) => {
    const localeTo = to.params.locale
    const localeFrom = from.params.locale

    // Find the language
    const languageTo = languages.getLanguageByLocale(localeTo as string, true)

    debugBeforeEach('localeTo:' + localeTo)
    debugBeforeEach('localeFrom:' + localeFrom)
    debugBeforeEach('Language found from locale %O', languageTo)

    const testInfiniteRedirect = () => {
      if (to.redirectedFrom) {
        debugBeforeEachRedirectLoop('to: %O', to)
        debugBeforeEachRedirectLoop('to.redirectedFrom: %O', to.redirectedFrom)
        debugBeforeEachRedirectLoop('from: %O', from)
        // eslint-disable-next-line no-console
        console.warn(`Warning: possible infinite redirect loop (${to.redirectedFrom.fullPath} -> ${to.fullPath})`)
        return true
      } else {
        return false
      }
    }

    // Language we are navigating to is undefined
    if (!languageTo) {
      if (testInfiniteRedirect()) {
        // There has already been a redirect, don't do it again
        return next()
      } else {
        debugBeforeEachNoLanguageFound('Invalid language provided, reloading application')
        // Changing the language causes a page reload, so we can just return to the home page
        // and let the root-app handle the language
        return next('/')
      }
    }

    // An olyslager locale is used
    if (localeTo === languageTo.code) {
      if (testInfiniteRedirect()) {
        // There has already been a redirect, don't do it again
        return next()
      } else {
        to.params.locale = languageTo.code2
        to.path = '/' + languageTo.code2
        debugBeforeEach('olyslager locale used, redirect to iso2 locale')
        debugBeforeEach('to.params.locale: ' + to.params.locale)
        debugBeforeEach('to.path: ' + to.path)
        debugBeforeEach('to: %O', to)
        // Redirect to iso2 locale
        return next(to)
      }
    }

    if (i18nInstance) {
      // Set the i18n locale
      const localeRef = i18nInstance.global.locale as WritableComputedRef<string>
      localeRef.value = localeTo as string
    }

    // Set the html lang
    if (localeTo) {
      document.querySelector('html')?.setAttribute('lang', localeTo as string)
    }

    return next()
  })

  debugCreateRouter('Created router %O', router)

  return router
}

const moaRouter = { createRouter }

export default moaRouter
