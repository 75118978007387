<script setup lang="ts">
import { Ref, ref } from 'vue'
import InlineSvg from 'vue-inline-svg'

const props = defineProps({
  src: {
    type: String,
    required: false,
    default: null
  },
  size: {
    type: Number,
    required: false,
    default: 20
  },
  inline: {
    type: Boolean,
    required: false,
    default: false
  }
})

const iconUrlCss: Ref<string> = ref(`url(${props.src})`)
const sizeCss: Ref<string> = ref(`${props.size}px`)

</script>

<template>
  <InlineSvg
    v-if="inline"
    :src="src"
    :width="size"
    :height="size"
  />
  <div v-else />
</template>

<style scoped lang="scss">
div {
  width: v-bind(sizeCss);
  height: v-bind(sizeCss);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-image: v-bind(iconUrlCss);
  mask-image: v-bind(iconUrlCss);
  background-color: #000;
}
</style>
