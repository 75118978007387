<script lang="ts">
import { defineComponent, provide, ref } from 'vue'
export default defineComponent({
  name: 'OlyHoverContainer',
  setup () {
    const isActive = ref(false)

    provide('hoverActive', isActive)

    return { isActive }
  }
})
</script>

<template>
  <div
    class="hover-container"
    @mouseenter="isActive = true"
    @mouseleave="isActive = false"
  >
    <!-- @slot default you can put elements that need a hover effect inside this slot-->
    <slot :hover-active="isActive" />
  </div>
</template>

<style scoped>

</style>
