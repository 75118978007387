export const clickOutside = {
  beforeMount: (el: { clickOutsideEvent: { (event: { target: unknown }): void; (this: Document, ev: MouseEvent): unknown }; contains: (arg0: unknown) => unknown }, binding: { value: () => void }) => {
    el.clickOutsideEvent = (event: { target: unknown }) => {
      // check if click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value()
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el: { clickOutsideEvent: (this: Document, ev: MouseEvent) => unknown }) => {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
}
