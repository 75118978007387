import { inject } from 'vue'

import { Frontend } from '@olyslager/customer-configuration'

export default function useDemo () {
  const frontend = inject('Config') as Frontend

  let demoFeaturesEnabled = false

  if (frontend && frontend.properties && frontend.properties.enableDemoFeatures) {
    demoFeaturesEnabled = frontend.properties.enableDemoFeatures as boolean
  }

  return { demoFeaturesEnabled }
}
