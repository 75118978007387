<script setup lang="ts">
import { Ref, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  scrollSpeed: {
    type: Number,
    required: false,
    default: 2
  },
  scrollStep: {
    type: Number,
    required: false,
    default: 0
  },
  numberOfItems: {
    type: Number,
    required: false,
    default: 0
  }
})

defineSlots<{
  prev:(props: { active: boolean, prev: () => void }) => void
  default: (contents: object) => void
  next: (props: { active: boolean, next: () => void }) => void
}>()

const scrollContainer: Ref<HTMLElement | undefined> = ref()
const prevActive: Ref<boolean> = ref(false)
const nextActive: Ref<boolean> = ref(true)
const hasArrowButtons: Ref<boolean> = ref(false)

const scroll = (begin: number, end: number, speed: number) => {
  const container = scrollContainer.value
  if (!container) { return }

  const duration = 1000
  let starttime = 0

  const step = function (timestamp: number) {
    if (!starttime) {
      starttime = timestamp
    }

    const runtime = timestamp - starttime
    // progress is between 0 (starting) and 1 (complete)
    const relativeProgress = runtime / duration

    if (runtime < duration) {
      requestAnimationFrame(step)
    }

    begin = begin + (end - begin) * (Math.min(relativeProgress * speed, 1))

    container.scrollLeft = begin
  }
  requestAnimationFrame(step)
}

const getScrollStep = () => {
  if (scrollContainer.value && props.scrollStep === 0 && props.numberOfItems !== 0) {
    return scrollContainer.value.scrollWidth / props.numberOfItems
  }
  return props.scrollStep
}

const reset = () => {
  if (scrollContainer.value) {
    scrollContainer.value.scrollLeft = 0
    hasArrowButtons.value = scrollContainer.value.offsetWidth !== scrollContainer.value.scrollWidth
    prevActive.value = false
    nextActive.value = true
  }
}

const prev = () => {
  if (scrollContainer.value) {
    const scrollStep = getScrollStep()
    scroll(scrollContainer.value.scrollLeft, scrollContainer.value.scrollLeft - scrollStep, props.scrollSpeed)
    prevActive.value = scrollContainer.value.scrollLeft - scrollStep > 0
    nextActive.value = true
  }
}

const next = () => {
  if (scrollContainer.value) {
    const scrollStep = getScrollStep()
    scroll(scrollContainer.value.scrollLeft, scrollContainer.value.scrollLeft + scrollStep, props.scrollSpeed)
    prevActive.value = true
    nextActive.value = scrollContainer.value.scrollLeft + scrollStep + scrollContainer.value.offsetWidth < scrollContainer.value.scrollWidth
  }
}

const resizeObserver = new ResizeObserver(function () {
  if (scrollContainer.value) {
    reset()
  }
})

onMounted(() => {
  if (scrollContainer.value) {
    resizeObserver.observe(scrollContainer.value)
    reset()
  }
})

onUnmounted(() => {
  if (scrollContainer.value) {
    resizeObserver.unobserve(scrollContainer.value)
  }
})

</script>

<template>
  <div class="scrollcontainer">
    <slot
      v-if="hasArrowButtons"
      name="prev"
      :active="prevActive"
      :prev="prev"
    />

    <div
      ref="scrollContainer"
      class="scrollcontainer__items"
    >
      <slot />
    </div>

    <slot
      v-if="hasArrowButtons"
      name="next"
      :active="nextActive"
      :next="next"
    />
  </div>
</template>

<style scoped lang="scss">
.scrollcontainer {
  display: flex;
  align-items: center;
  gap: 10px;
  &__items {
    overflow-x: hidden;
  }
}
</style>
