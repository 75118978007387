import { onBeforeMount, onUnmounted, ref } from 'vue'

type Breakpoint = 'sm' | 'md' | 'lg'

interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
}

interface ResponsiveSize {
  greaterOrEqual: (breakpoint: Breakpoint) => boolean;
  smaller: (breakpoint: Breakpoint) => boolean;
  equal: (breakpoint: Breakpoint) => boolean;
}

const breakpoints: Breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024
}

export default function useResponsive (): ResponsiveSize {
  const containerWidth = ref(0)
  const container = ref<HTMLElement | null>(null)
  let resizeObserver: ResizeObserver | null = null

  onBeforeMount(() => {
    container.value = document.querySelector('main#maincontent')
    if (container.value) {
      containerWidth.value = container.value.clientWidth
      resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          containerWidth.value = entry.contentRect.width
        }
      })
      resizeObserver.observe(container.value)
    }
  })

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect()
      resizeObserver = null
    }
  })

  const greaterOrEqual = (breakpoint: Breakpoint) => {
    return containerWidth.value >= breakpoints[breakpoint]
  }

  const smaller = (breakpoint: Breakpoint) => {
    return containerWidth.value < breakpoints[breakpoint]
  }

  const equal = (breakpoint: Breakpoint) => {
    if (breakpoint === 'sm') {
      return containerWidth.value >= breakpoints.sm && containerWidth.value < breakpoints.md
    }
    if (breakpoint === 'md') {
      return containerWidth.value >= breakpoints.md && containerWidth.value < breakpoints.lg
    }
    if (breakpoint === 'lg') {
      return containerWidth.value >= breakpoints.lg
    }
    return false
  }

  return { greaterOrEqual, smaller, equal }
}
