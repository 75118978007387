<script lang="ts" setup>
import { ref } from 'vue'

import OlyIcon from '../OlyIcon/OlyIcon.vue'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
    required: false
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  maxLength: {
    type: Number,
    required: false,
    default: 100
  },
  placeholderText: {
    type: String,
    required: false,
    default: ''
  },
  enableButton: {
    type: Boolean,
    required: false,
    default: true
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
  iconUrl: {
    type: String,
    required: false,
    default: ''
  },
  iconSize: {
    type: Number,
    required: false,
    default: 16
  },
  uppercase: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['submit', 'update:modelValue'])

const inputRef = ref(null)
function submit () {
  emit('submit', props.modelValue)
}

</script>

<template>
  <div class="olytextbox">
    <input
      ref="inputRef"
      type="text"
      class="olytextbox__input"
      :class="{uppercase}"
      :placeholder="placeholderText"
      :maxlength="maxLength"
      :value="modelValue"
      :disabled="disabled"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      @keyup.enter="submit"
    >
    <div
      v-if="loading"
      class="olytextbox__loading-icon"
    />
    <div
      v-if="enableButton"
      class="olytextbox__button"
      @click="submit"
    >
      <OlyIcon
        v-if="iconUrl"
        class="olytextbox__icon"
        :icon-url="iconUrl"
        icon-color="#fff"
        :height="iconSize"
        :width="iconSize"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  $defaultHeight: 34;

  .olytextbox {
    --inputWidth: 100%;
    --inputHeight: #{$defaultHeight}px;
    --buttonWidth: #{$defaultHeight - 8}px;
    --inputPadding: 0 4px;
    --inputTextSize: inherit;

    --inputBorderColor: var(--main-input-color-3);
    --inputBackgroundColor: var(--main-input-color-1);
    --inputTextColor: var(--main-font-color-4);
    --buttonBackgroundColor: var(--main-input-color-2);
    --loadingIconColor: var(--main-input-color-2);
    --buttonIconColor: var(--main-input-color-1);

    position: relative;
    &__input {
      width: var(--inputWidth);
      height: var(--inputHeight);
      padding: var(--inputPadding);
      background-color: var(--inputBackgroundColor);
      font-size: var(--inputTextSize);
      color: var(--inputTextColor);
      border: 1px solid var(--inputBorderColor);
      border-radius: 5px;
      &.uppercase {
        text-transform: uppercase;
      }
    }
    &__button {
      position: absolute;
      background-color: var(--buttonBackgroundColor);
      border-radius: 2px;
      cursor: pointer;
      inset-inline-end: 4px;
      top: 4px;
      bottom: 4px;
      width: var(--buttonWidth);
      padding: 0;
      display: flex;
      .olytextbox__icon {
        margin: auto;
      }
    }
    &__loading-icon {
      display: inline-block;
      position: absolute;
      width: var(--buttonWidth);
      inset-inline-end: var(--inputHeight);
      top: 4px;
      bottom: 4px;
      &:after {
        content: " ";
        display: block;
        width: 15px;
        height: 15px;
        margin: 4px;
        border-radius: 50%;
        border: 2px solid var(--loadingIconColor);
        border-color: var(--loadingIconColor) transparent var(--loadingIconColor) transparent;
        animation: olytextbox__loading-icon 1.2s linear infinite;
      }
    }
  }

@keyframes olytextbox__loading-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
