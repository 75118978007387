<script setup lang="ts">
import { RendererElement } from 'vue'

const hidePanel = (el: RendererElement): void => {
  el.style.opacity = 0
  el.style.height = 0
}

const slidePanel = (el: RendererElement): void => {
  el.style.height = el.scrollHeight + 'px'
  el.style.opacity = 1
}

const showPanel = (el: RendererElement): void => {
  el.style.height = 'auto'
}
</script>

<template>
  <Transition
    name="panel-transition-wrapper"
    @before-enter="hidePanel"
    @enter="slidePanel"
    @after-enter="showPanel"
    @before-leave="slidePanel"
    @leave="hidePanel"
  >
    <slot />
  </Transition>
</template>

<style scoped>

</style>
